@import "styles/_breakpoints.scss";

.globe-popover {
    position: fixed;
    top: 60%;
    left: 50%;
    right: auto;
    bottom: auto;
    border: none;
    margin-right: -50%;
    transform: translate(-50%, -50%);
    width: calc(100% - 30px);
    max-width: 400px;
    background-color: #FF007F;
    color: white;
    border-radius: 10px;
    padding: 15px;
    outline: none;
    box-shadow: 0 3px 6px rgba(0,0,0,0.16);
}

.globe-popover-overlay {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0,0,0,0);
    z-index: "20";

    opacity: 0;
    transition: opacity 500ms ease-in-out;
}

.ReactModal__Overlay--after-open{
    opacity: 1;
}

.ReactModal__Overlay--before-close{
    opacity: 0;
}

@media (min-width: $md) {
    .globe-popover {
        top: 50%;
        left: 65%;
    }
}