@font-face {
  font-family: "EnergyDisplay";
  src: url(./fonts/EnergyW00-Display.woff2) format("woff2"),
    url(./fonts/EnergyW00-Display.woff) format("woff");
}

@font-face {
  font-family: "EnergyRegular";
  src: url(./fonts/EnergyW01-Regular.woff2) format("woff2"),
    url(./fonts/EnergyW01-Regular.woff) format("woff");
}

@font-face {
  font-family: "EnergyBold";
  src: url(./fonts/EnergyW01-Bold.woff2) format("woff2"),
    url(./fonts/EnergyW01-Bold.woff) format("woff");
}

@font-face {
  font-family: "EnergyLight";
  src: url(./fonts/EnergyW01-Light.woff2) format("woff2"),
    url(./fonts/EnergyW01-Light.woff) format("woff");
}
