.slick-slider,
.slick-list,
.slick-track,
.slick-slide > div {
  height: 100%;

  &:focus {
    outline: none;
  }
}

.slick-arrow {
  z-index: 1;

  &.slick-disabled {
    display: none !important;
  }
}

.slick-prev {
  left: 25px;
}
.slick-next {
  right: 25px;
}

@import "styles/_colors.scss";
@import "styles/_breakpoints.scss";

// dots
.slick-dots {
  bottom: 25px;

  // @media (min-width: $sm) {
  //   text-align: left;
  //   left: 100px;
  // }

  @media (min-width: $md) {
    text-align: left;
    left: 138px;
  }

  li {
    button {
      width: 30px;
      height: 30px;
      &:before {
        width: 30px;
        height: 30px;
        font-size: 15px;
        color: $grey;
        opacity: 1;
      }
    }

    &.slick-active button {
      &:before {
        color: $primary;
        opacity: 1;
      }
    }
  }
}

// .carousel .control-dots .dot {
//   opacity: 1;
//
//   &.selected {
//     background: $secondary;
//   }
// }
